
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator';
import { DropPositionMixin } from '../../../fruitpackage/mixins';
import anime from 'animejs';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  data() {
    return {
      active: false,
      proxyPosition: null,
      dropClass: 'dropdown__target',
      animSpeed: 300
    };
  },

  mixins: [DropPositionMixin],

  created() {
    this.proxyPosition = this.position;
  },

  mounted() {
    this.rootListener();
  },

  computed: {
    cClass() {
      let classes = ['dropdown'];

      if (this.active) classes.push('is-active');

      return classes.join(' ');
    },

    cTargetClass() {
      return `dropdown__target dropdown__target--${this.proxyPosition}`;
    }
  },

  methods: {
    rootListener() {
      this.$root.$on('root::dropdown::toggle', (event, uid) => {
        if (this._uid === uid) return;
        this.active = false;
        this.animateToggle(0);
      });

      this.$root.$on('root::dropdown::close', event => {
        this.active = false;
        this.animateToggle(0);
      });

      this.$root.$on('root::click', event => {
        this.active = false;
        this.animateToggle(0);
      });
    },
    animateToggle(n) {
      let el = document.querySelector('.filter-menu__trigger');
      if (!el) {
        return;
      }
      let triggerHeight = el.offsetHeight - 1;
      let height = this.active ? (n + 1) * triggerHeight + 1 : triggerHeight;
      height > triggerHeight * 10 ? (height = triggerHeight * 10 + 1) : (height = height);
      anime({
        targets: this.$el,
        height: height,
        duration: this.animSpeed,
        easing: 'easeOutQuint'
      });
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Dropdown extends Vue {
  @Prop({ default: 'left' }) position!: string;

  @Emit()
  toggle(event) {
    this.active = !this.active;
    this.$root.$emit('root::dropdown::toggle', event, this._uid);
    console.log('this.$slots.target.length: ', this.$slots.target.length);
    this.animateToggle(this.$slots.target.length);
    return this.active;
  }

  @Watch('active')
  onActiveChange(val: boolean) {
    if (!val) {
      this.resetProxyPosition();
      return;
    }

    this.$nextTick(function() {
      this.updateProxyPosition();
    });
  }

  @Watch('$route')
  onRouteChange() {
    this.active = false;
  }
}
