
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { AppStore } from '@interface/store/App';
import Dropdown from '@part/elements/Dropdown.vue';
import SelectDropdown from '@part/elements/SelectDropdown.vue';
import ArrowOpenSvg from '@svg/ArrowOpen.vue';
import CrossCircleSvg from '@svg/CrossCircle.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    Dropdown,
    ArrowOpenSvg,
    CrossCircleSvg,
    SelectDropdown,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    dateFilter: Boolean,
    genreFilter: Boolean,
    ambassadorFilter: Boolean,
    categoryFilter: Boolean,
  },
  data() {
    return {
      mobile: window.innerWidth <= 960
    };
  },
  computed: {
    ...mapState(['selectedAmbassador', 'selectedFilters', 'filteredEvents']),
    museumsByLocation() {
      // return all if none selceted
      if (!this.selectedFilters.location.length) {
        return this.data.data.filters.museum;
      }

      // else return only the corresponding ones
      let $this = this;
      let filteredMuseums = this.data.data.filters.museum.filter(function(itm) {
        return $this.selectedFilters.location.indexOf(itm['location_id']) > -1;
      });
      return filteredMuseums;
    }
  },
  methods: {
    closeOverlay() {
      this.$emit('close', '');
    },
    ambassadorClicked(type, filter, i) {
      this.mobile && this.closeOverlay();
      console.log({ type: type, filterId: filter, i: i });
      this.$emit('ambassadorClick', { type: type, filterId: filter, i: i });
    },
    filterClicked(type, filter) {
      if(type === 'date'){
        this.$emit('resetfilters', { type: type });
      }
      this.mobile && this.closeOverlay();
      this.$emit('filterclick', { type: type, filterId: filter });
    },
    resetFilters(type, filter) {
      this.$emit('resetfilters', '');
    },
    checkIfActiveAmbassador(id) {
      return this.selectedAmbassador.id == id;
    },
    checkIfActive(type, id) {
      return !(this.selectedFilters[type].indexOf(id) < 0);
    },
    checkIfDisabled(type, id) {
      let items = this.filteredEvents.data.filter(function(item) {
        return item[`${type}_id`] == id;
      });

      return false;
    },
    nRemaining(type, id) {
      let items = this.filteredEvents.data.filter(function(item) {
        return item[`${type}_id`] == id;
      });

      return items.length;
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class FilterMenu extends Vue {}
